<template>
  <div>
    <meta name="robots" content="noindex">
    <h1> 404 </h1>
    <h2> Page Not Found </h2> <br>
    <p> Sorry, the page you were looking for could not be found. </p>
    <p> Please select an option from the menu or search for what you are looking for </p>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  data () {
    return {

    }
  }
}
</script>

<style scoped>
/* <style scoped lang="less"> */
/* @import '../../less/elements/color.less'; */

div{
  text-align: center;
}
h1{
  font-size: 10rem;
  /* color: @color-border; */
}
P {
  margin-bottom: 0;
}
</style>
